<template>
  <div class="content">
    <div class="top flex-sp-bt-center">
      <div>
        <a-input
            allowClear
            v-model="params.sampleId"
            class="w-100 mb-5"
            placeholder='样本库ID'
        ></a-input>
        <a-input
            allowClear
            v-model="params.dikuId"
            class="w-100 mb-5 ml-5"
            placeholder='底库ID'
        ></a-input>
        <!-- 选择大币种 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeSelectBigCoinSearch"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            class="ml-5 mb-5 w-150"
            v-model="params.coinId"
            placeholder="大币种"
        >
          <a-select-option
              v-for="item of bigCoinList"
              :key="item.coinId"
              :value="item.coinId"
          >{{ item.coinName }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeSelectSmallCoin"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            class="ml-5 mb-5 w-150"
            v-model="params.itemId"
            placeholder="小币种"
        >
          <a-select-option
              v-for="item of smallCoinList"
              :key="item.coinKindItemId"
              :value="item.coinKindItemId"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @select="search"
            showSearch
            :filter-option="untils.filterOption"
            class="ml-5 mb-5 w-250"
            v-model="params.versionId"
            :dropdownMenuStyle="{'max-height': '500px'}"
            placeholder="版别"
        >
          <a-select-option
              v-for="item of versionList"
              :key="item.coinVersionId"
              :value="item.coinVersionId"
          >{{ `【${item.level ?  item.level : "无"}】 ${item.coinVersionName}(${item.size || 0})` }}</a-select-option>
        </a-select>
        <a-select
            allowClear
            showSearch
            class="w-200 ml-5 mb-5"
            v-model="params.updateTimeSort"
            placeholder="更新时间排序"
        >
          <a-select-option
              v-for="item of sortStatusTypeList"
              :key="item.value"
              :value="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>
        <a-select
            allowClear
            showSearch
            class="w-200 ml-5 mb-5"
            v-model="params.scoreSort"
            placeholder="分数排序"
        >
          <a-select-option
              v-for="item of sortStatusTypeList"
              :key="item.value"
              :value="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>
        <a-button
            @click="search"
            class="ml-5 mb-5"
            type="primary"
            size="small"
        >搜索</a-button>
      </div>
    </div>
    <div class="table-content">
      <TableList
          :list="listData"
          :pagination="pagination"
          @changePage="handleChangePage"
          @success="getList"
      />
    </div>
  </div>
</template>
<script>
import untils from "@/untils"
import TableList from "@/views/cmsPage/versionManage/sampleDuiBanManage/_components/TableList/index.vue"
import {
  getSampleDuiBanListData,
  getStoreVersionCountDataApi
} from "@/views/cmsPage/versionManage/sampleDuiBanManage/_apis"
import {sortStatusTypeList} from "@/views/cmsPage/versionManage/sampleDuiBanManage/_data"

export default {
  components: {TableList,},
  data() {
    return {
      untils,
      listData: [],
      bigCoinList: [],
      smallCoinList: [],
      versionList: [],
      sortStatusTypeList,
      params: {
        pageSize: 10,
        pageNum: 1,
        sampleId: undefined,
        dikuId: undefined,
        coinId: undefined,
        versionId: undefined,
        updateTimeSort: undefined, //  更新时间  排序 0 升序 1 降序 X掉不传或null
        scoreSort: undefined,  // 分值  排序 0 升序 1 降序 X掉不传或null
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      kaiYuanCoinFrontTextList: [],
    }
  },
  async created() {
    await this.getList()
    await this.getBigCoin()
  },
  methods: {
    /** 获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getSampleDuiBanListData(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.listData = res.data.records
      this.pagination.total = res.data.total
      await this.getStoreVersionCountEvent()
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page
      this.$set(this.pagination, "current", current)
      this.$set(this.params, "pageNum", current)
      this.getList()
    },
    /** 搜索 */
    search() {
      this.$set(this.pagination, "current", 1)
      this.$set(this.params, "pageNum", 1)
      this.getList()
    },
    /** 获取版别底库数量 */
    async getStoreVersionCountEvent() {
      const versionIds = this.getVersionIdStr()
      const res = await getStoreVersionCountDataApi({versionIds})
      if (res.status !== '200') return
      const temp = JSON.parse(JSON.stringify(this.listData))
      temp.forEach(el => {
        el.dataArray.forEach(item => {
          item.versionCount = res.data[item.versionId]
        })
      })
      this.listData = temp
    },
    /** 获取versionId字符串 */
    getVersionIdStr() {
      let versionIds = []
      this.listData.forEach(el => {
        versionIds = versionIds.concat(el.dataArray.map(item => item.versionId))
      })
      return versionIds.join(',')
    },
    /** 获取大币种 */
    async getBigCoin() {
      const res = await this.axios("/dq_admin/duibanSampleData/listCoins");
      if (res.status !== '200') return;
      this.bigCoinList = res.data;
    },
    /** 选择大币种 */
    changeSelectBigCoinSearch(val) {
      delete this.params.itemId;
      delete this.params.versionId;
      if (!val) {
        this.smallCoinList = [];
        this.versionList = [];
        return;
      }
      this.getSmallCoinList();
    },
    /** 查询小币种 */
    async getSmallCoinList() {
      const res = await this.axios("/dq_admin/duibanSampleData/listCoinItems", {
        params: {
          coinId: this.params.coinId,
        },
      });
      if (res.status !== '200') return;
      this.smallCoinList = res.data;
    },
    /** 选择小币种 */
    async changeSelectSmallCoin(val) {
      delete this.params.versionId;
      if (!val) {
        this.versionList = [];
        return;
      }
      await this.getVersion();
    },
    // 获取版别
    async getVersion() {
      const res = await this.axios("/dq_admin/duibanSampleData/listCoinVersions", {
        params: {
          coinId: this.params.coinId,
          coinItemId: this.params.itemId,
        },
      });
      if (res.status != 200) return;
      this.versionList = res.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.bgf {
  background-color: #fff !important;
  border: 1px solid #000c17 !important;
  color: #000c17 !important;
  margin-left: 20px !important;
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
.ml-5 {
  margin-left: 5px;
}
</style>
