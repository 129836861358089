/** 样本对版管理列表展示元素 */
export const sampleDuiBanManageListTableColumns = [
    { title: "样本ID", align: "center", scopedSlots: {customRender: 'itemIdSlot'}, width: 100 },
    { title: "图片", align: "center", scopedSlots: {customRender:  'itemImageSlot'}, width: 200 },
    { title: "相关性", align: "left", scopedSlots: {customRender: 'itemTieUpSlot'}, width: 300 },
    { title: "底库存在", align: "center", scopedSlots: {customRender: 'itemDiKuHaveStatusSlot'}, width: 100 },
    { title: "更新时间", align: "center", dataIndex: 'updateTime', width: 150},
    { title: "操作人", align: "center", dataIndex: 'adminName'},
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" }, width: 200 },
]

/** 排序状态 */
export const sortStatusTypeList = [
    {name: '升序', value: 0},
    {name: '降序', value: 1},
]
