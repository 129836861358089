var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: "操作日志",
        placement: "right",
        width: "60vw",
        closable: true,
        visible: _vm.visible
      },
      on: { close: _vm.onClose }
    },
    [
      _c(
        "div",
        { staticStyle: { height: "80vh", "overflow-y": "scroll" } },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.list,
              rowKey: function(record, index) {
                return index
              }
            },
            on: { change: _vm.changePage }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }