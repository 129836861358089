var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemIdSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  {
                    staticClass: "cur-pot color-blue",
                    on: {
                      click: function($event) {
                        return _vm.handleGoToSampleStoreList(row)
                      }
                    }
                  },
                  [_vm._v(_vm._s(row.id))]
                )
              ])
            }
          },
          {
            key: "itemImageSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.frontImage
                  ? _c(
                      "div",
                      { staticClass: "img-list" },
                      _vm._l(
                        [
                          row.cutFrontImage || row.frontImage,
                          row.cutBackImage || row.backImage
                        ],
                        function(item, index) {
                          return _c("img", {
                            key: index,
                            attrs: { src: item, alt: "" },
                            on: {
                              click: function($event) {
                                return _vm.handlePreviewImg(
                                  [row.frontImage, row.backImage],
                                  index
                                )
                              }
                            }
                          })
                        }
                      ),
                      0
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemTieUpSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                _vm._l(row.dataArray, function(item, index) {
                  return _c("div", { key: index, staticClass: "tieUpList" }, [
                    _c("div", { staticClass: "tieUpItem" }, [
                      item.versionCount
                        ? _c("span", { staticClass: "color-black" }, [
                            _vm._v("【" + _vm._s(item.versionCount) + "】")
                          ])
                        : _vm._e(),
                      _c("span", [
                        _vm._v(_vm._s(item.itemName + "·" + item.versionName))
                      ]),
                      _c("span", { staticClass: "color-green" }, [
                        _vm._v("（" + _vm._s(item.versionScore) + "）")
                      ])
                    ])
                  ])
                }),
                0
              )
            }
          },
          {
            key: "itemDiKuHaveStatusSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  { class: row.isDiku ? "color-green" : "color-gray" },
                  [_vm._v(_vm._s(row.isDiku ? "存在" : "无"))]
                )
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "m-5",
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleMoveVersion(row)
                          }
                        }
                      },
                      [_vm._v("挪动")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticClass: "m-5",
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleResetVersion(row)
                          }
                        }
                      },
                      [_vm._v("重新对版")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "m-5",
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleDependSampleVersion(row)
                          }
                        }
                      },
                      [_vm._v("原图对版")]
                    ),
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确定删除？",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleDelete(row)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "m-5",
                            attrs: { size: "small", type: "danger" }
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "m-5 w-100",
                        attrs: { size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleShowLogDetail(row)
                          }
                        }
                      },
                      [_vm._v("日志")]
                    )
                  ],
                  1
                )
              ])
            }
          }
        ])
      }),
      _c("LogDetailPopup", { ref: "logDetailPopupEl" }),
      _c("MoveVersionPopup", {
        ref: "moveVersionPopupEl",
        on: { success: _vm.handleSuccess }
      }),
      _c("PictureVersionPopup", { ref: "pictureVersionPopupEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }