<template>
  <a-drawer
      title="操作日志"
      placement="right"
      :width="'60vw'"
      :closable="true"
      :visible="visible"
      @close="onClose"
  >
    <div style="height: 80vh; overflow-y: scroll">
      <a-table
          @change="changePage"
          :pagination="pagination"
          :loading="loading"
          :columns="columns"
          :data-source="list"
          :rowKey="(record, index) => index"
      >
      </a-table>
    </div>
  </a-drawer>
</template>

<script>
import {getLogDetailData} from "@/views/cmsPage/versionManage/sampleDuiBanManage/_apis"

export default {
  data() {
    return {
      notes: undefined,
      visible: false,
      list: [],
      loading: false,
      params: {
        pageNum: 1,
        pageSize: 20,
        libraryType: 0,
        libraryId: ""
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      columns: [
        {
          align: "center",
          title: "操作时间",
          dataIndex: "createTime",
          width: 120,
        },
        {
          align: "center",
          title: "操作用户",
          dataIndex: "adminName",
          width: 120,
        },
        {
          align: "center",
          title: "操作日志",
          dataIndex: "actionContent",
        },

      ],
    };
  },
  methods: {
    async show(data){
      this.notes = undefined
      this.$set(this.params, "libraryId", data.id)
      this.$loading.show()
      await this.getList()
    },
    async getList() {
      this.loading = true;
      const res = await getLogDetailData(this.params)
      this.$loading.hide()
      this.loading = false;
      if (res.status !== '200') return (this.list = []);
      this.list = res.data.records;
      this.pagination.total = res.data.total;
      this.visible = true
    },
    // 切换分页
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList();
    },
    onClose() {
      this.visible = false;
      setTimeout(() => {
        this.$emit("close");
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.notes {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  input {
    width: 300px;
    margin-right: 20px;
  }
}
::v-deep {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    height: 60px !important;
  }
}
</style>