<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   ID   -->
      <div slot="itemIdSlot" slot-scope="row">
        <span class="cur-pot color-blue" @click="handleGoToSampleStoreList(row)">{{ row.id }}</span>
      </div>
<!--   图片列表   -->
      <div slot="itemImageSlot" slot-scope="row">
        <div class="img-list" v-if="row.frontImage">
          <img
              v-for="(item, index) in [row.cutFrontImage || row.frontImage, row.cutBackImage || row.backImage]"
              :key="index"
              :src="item"
              alt=""
              @click="handlePreviewImg([row.frontImage, row.backImage], index)"
          >
        </div>
      </div>
<!--   关联关系   -->
      <div slot="itemTieUpSlot" slot-scope="row">
        <div class="tieUpList" v-for="(item, index) in row.dataArray" :key="index">
          <div class="tieUpItem">
            <span class="color-black" v-if="item.versionCount">【{{ item.versionCount }}】</span>
            <span>{{ item.itemName + '·' + item.versionName }}</span>
            <span class="color-green">（{{ item.versionScore }}）</span>
          </div>
        </div>
      </div>
<!--   底库是否存在   -->
      <div slot="itemDiKuHaveStatusSlot" slot-scope="row">
        <div :class="row.isDiku ? 'color-green' : 'color-gray'">{{ row.isDiku ? '存在' : '无' }}</div>
      </div>
<!--   操作   -->
      <div slot="itemActionSlot" slot-scope="row">
        <div>
          <a-button
              class="m-5"
              size="small"
              type="primary"
              @click="handleMoveVersion(row)"
          >挪动</a-button>
          <a-button
              class="m-5"
              size="small"
              type="primary"
              @click="handleResetVersion(row)"
          >重新对版</a-button>
        </div>
        <div>
          <a-button
              class="m-5"
              size="small"
              type="primary"
              @click="handleDependSampleVersion(row)"
          >原图对版</a-button>
          <a-popconfirm
              title="确定删除？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleDelete(row)"
          >
            <a-button
                class="m-5"
                size="small"
                type="danger"
            >删除</a-button>
          </a-popconfirm>
        </div>
        <div>
          <a-button
              class="m-5 w-100"
              size="small"
              @click="handleShowLogDetail(row)"
          >日志</a-button>
        </div>
      </div>
    </a-table>
<!--  日志详情  -->
    <LogDetailPopup ref="logDetailPopupEl"/>
<!--  挪动  -->
    <MoveVersionPopup ref="moveVersionPopupEl" @success="handleSuccess"/>
<!--  原图对版  -->
    <PictureVersionPopup ref="pictureVersionPopupEl"/>
  </div>
</template>
<script>
import {sampleDuiBanManageListTableColumns} from "@/views/cmsPage/versionManage/sampleDuiBanManage/_data"
import {
  deleteSampleDuiBanVersion,
  deleteSampleVersion,
  getResetVersion
} from "@/views/cmsPage/versionManage/sampleDuiBanManage/_apis"
import LogDetailPopup from "@/views/cmsPage/versionManage/sampleDuiBanManage/_components/LogDetailPopup/index.vue"
import MoveVersionPopup from "@/views/cmsPage/versionManage/sampleDuiBanManage/_components/MoveVersionPopup/index.vue"
import PictureVersionPopup
  from "@/views/cmsPage/versionManage/sampleDuiBanManage/_components/PictureVersionPopup/index.vue"

export default {
  props: ['pagination', 'list'],
  components: {LogDetailPopup, MoveVersionPopup, PictureVersionPopup},
  data() {
    return {
      tableList: sampleDuiBanManageListTableColumns,
      isLoading: false,
    }
  },
  methods: {
    /** 去往样本库 */
    handleGoToSampleStoreList(row) {
      const origin = location.origin
      const url = `${origin}/#/cms/versionManage/sampleStore?id=${row.id}`;
      window.open(url)
    },
    /** 挪动 */
    handleMoveVersion(row) {
      this.$refs.moveVersionPopupEl.show(row)
    },
    /** 重新对版 */
    async handleResetVersion(row) {
      if (this.isLoading) return this.$message.warn('请勿重复点击')
      this.isLoading = true
      this.$loading.show()
      const res = await getResetVersion({sampleId: row.id})
      this.$loading.hide()
      this.isLoading = false
      if (res.status !== '200') return
      this.$message.info('对版成功')
      this.handleSuccess()
    },
    /** 查看日志详情 */
    handleShowLogDetail(row) {
      this.$refs.logDetailPopupEl.show(row)
    },
    /** 相关样本 */
    async handleDependSampleVersion(row) {
      await this.$refs.pictureVersionPopupEl.show(row, 1, row.coinVersionId, false)
    },
    /** 删除 */
    async handleDelete(row) {
      const res = await deleteSampleVersion({recordId: row.id})
      if (res.status !== '200') return
      const result = await deleteSampleDuiBanVersion({sampleId: row.id})
      if (result.status !== '200') return
      this.$message.info('删除成功')
      this.handleSuccess()
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 成功 */
    handleSuccess() {
      this.$emit('success')
    },
    /** 查看大图 */
    handlePreviewImg(images, index) {
      const urls = images.map(el => {
        return {img_url: el}
      })
      this.$previewImg({
        list: urls,
        baseImgField: "img_url",
        showMute: false,
        current: index
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.tag-class {
  margin-bottom: 5px;
}
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 100px;
  }
}
</style>