import instance from "@/axios"

/** 获取样本对版管理列表数据 */
export const getSampleDuiBanListData = async (params) => {
    return instance({
        url: '/dq_admin/duibanSampleAutoRecord/listData',
        method: 'GET',
        params: params
    })
}

/** 获取版别底库数量 */
export const getStoreVersionCountDataApi = async (params) => {
    return instance({
        url: '/dq_admin/duibanUserRecord/getConfirmDataSizeByVersionId',
        method: 'GET',
        params: params
    })
}
/** 重新对版 */
export const getResetVersion = async (params) => {
    return instance({
        url: '/dq_admin/duibanSampleAutoRecord/duiban',
        method: 'GET',
        params: params
    })
}
/** 删除 */
export const deleteSampleVersion = async (params) => {
    return instance({
        url: '/dq_admin/duibanSampleData/delById',
        method: 'GET',
        params: params
    })
}
/** 删除样本对版记录 */
export const deleteSampleDuiBanVersion = async (params) => {
    return instance({
        url: '/dq_admin/duibanSampleAutoRecord/delBySampleId',
        method: 'GET',
        params: params
    })
}
/** 获取日志详情 */
export const getLogDetailData = async (params) => {
    return instance({
        url: '/dq_admin/duibanConfirmDataLog/getConfirmDataLog',
        method: 'GET',
        params: params
    })
}